import React from "react";
import MainLayout from "../../layouts/MainLayout";
import { Card } from "flowbite-react";
import DomainsTable from "./components/table";
import { useAuth } from "../../utils/helpers/authWrapper";
import Spinner from "../../components/Spinner";

const Domain: React.FC = () => {
  const { domains, loading, user } = useAuth();

  return (
    <MainLayout>
      <div className="px-4 pt-6">
        {loading ? (
          <>
            <Spinner />
          </>
        ) : (
          <Card className="p-4">
            <h1 className="text-2xl font-semibold text-gray-800 dark:text-gray-200">
              Domains
            </h1>
            {
            user && <DomainsTable domains={domains} user={user}/>
}
          </Card>
        )}
      </div>
    </MainLayout>
  );
};

export default Domain;
